<template>
    <u-page-section-basic v-bind="$attrs">
        <template #pageSectionHead><slot name="pageSectionHead" /></template>

        <tit-wrap-primary v-if="this.$slots['tit']" :titWrapClass="titWrapClass" :titWrapSize="titWrapSize" :titWrapAlign="titWrapAlign" :titClass="titClass" :titSize="titSize" :txtClass="txtClass" :txtSize="txtSize">
            <slot name="tit" />
            <template #txt>
                <slot name="txt" />
            </template>
        </tit-wrap-primary>
        <slot />

        <template #pageSectionFoot><slot name="pageSectionFoot" /></template>
    </u-page-section-basic>
</template>

<script>
import UPageSectionBasic from "@/sets/styles/pages/u-page-section-basic.vue";
import TitWrapPrimary from "@/components/publish/parents/typography/tit-wrap-primary.vue";

export default {
    props: {
        titWrapClass: { type: String },
        titWrapSize: { type: String },
        titWrapAlign: { type: String },
        titClass: { type: String },
        titSize: { type: String },
        txtClass: { type: String },
        txtSize: { type: String },
    },
    components: {
        UPageSectionBasic,
        TitWrapPrimary,
    },
    data() {
        return {};
    },
    mounted() {},
    methods: {},
};
</script>

<style lang="scss" scoped></style>
