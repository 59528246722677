<template>
    <u-sub-head-basic v-bind="$attrs" tabsTag="u-tabs-filled" tabTag="u-tab-filled" :tabsAttrs="{ color: 'white', class: 'v-tabs--wide-filled tabs-filled2' }" class="sub-head--with-header sub-head--with-tabs">
        <template v-for="(_, slotName) in $slots" #[slotName]>
            <slot :name="slotName" />
        </template>
    </u-sub-head-basic>
</template>

<script>
import USubHeadBasic from "@/sets/styles/pages/u-sub-head-basic.vue";

export default {
    props: {},
    components: {
        USubHeadBasic,
    },
    data() {
        return {};
    },
    mounted() {},
    methods: {},
};
</script>

<style lang="scss" scoped>
::v-deep {
    .sub-visual {
        background-color: rgba(0, 0, 0, 0.25);
    }
    .v-tabs {
        background-color: transparent !important;
        &:before {
            background-color: rgba(0, 0, 0, 0.25) !important;
        }
    }
    .v-tab {
        color: #fff !important;
        background-color: transparent !important;
        &.v-tab {
            &--active {
                font-weight: 400;
                color: #fff !important;
                background-color: transparent !important;
                pointer-events: auto;
            }
            &--selected {
                font-weight: 700;
                color: var(--v-primary-base) !important;
                background-color: #fff !important;
                pointer-events: none;
            }
        }
    }
}
</style>
