var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('u-pagination-text-tile', _vm._b({
    on: {
      "input": _vm.search
    }
  }, 'u-pagination-text-tile', _vm.$attrs, false));

}
var staticRenderFns = []

export { render, staticRenderFns }