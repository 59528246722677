var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('u-card-flat-tile', _vm._b({}, 'u-card-flat-tile', Object.assign({
    color: _vm.color
  }, _vm.$attrs), false), [_vm.$slots['innerHead'] ? _vm._t("innerHead") : _vm._e(), _vm.innerClass || _vm.cardInnerSize ? [_c('div', {
    staticClass: "card-inner",
    class: [_vm.innerClass, _vm.cardInnerSize]
  }, [_vm._t("default")], 2)] : [_vm._t("default")], _vm.$slots['innerFoot'] ? _vm._t("innerFoot") : _vm._e()], 2);

}
var staticRenderFns = []

export { render, staticRenderFns }