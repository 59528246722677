var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('u-chip-flat-pill', _vm._b({
    attrs: {
      "color": "primary lighten-5"
    },
    on: {
      "click": function ($event) {
        return _vm.$emit('click');
      }
    }
  }, 'u-chip-flat-pill', _vm.$attrs, false), [_c('span', {
    staticClass: "primary--text"
  }, [_vm._t("default")], 2)]);

}
var staticRenderFns = []

export { render, staticRenderFns }