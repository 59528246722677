<template>
    <u-card-flat-tile v-bind="{ color, ...$attrs }">
        <slot v-if="$slots['innerHead']" name="innerHead" />
        <template v-if="innerClass || cardInnerSize">
            <div class="card-inner" :class="[innerClass, cardInnerSize]">
                <slot />
            </div>
        </template>
        <template v-else>
            <slot />
        </template>
        <slot v-if="$slots['innerFoot']" name="innerFoot" />
    </u-card-flat-tile>
</template>

<script>
import UCardFlatTile from "@/components/publish/styles/cards/u-card-flat-tile.vue";

export default {
    props: {
        color: { type: String, default: "grey lighten-5" },
        innerClass: { type: String, default: "" },
        cardInnerSize: { type: String, default: "" },
    },
    components: {
        UCardFlatTile,
    },
    data() {
        return {};
    },
    mounted() {},
    methods: {},
};
</script>

<style lang="scss" scoped></style>
