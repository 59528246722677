<template>
    <u-chip-flat-pill v-bind="$attrs" color="accent lighten-5" @click="$emit('click')">
        <span class="accent--text">
            <slot />
        </span>
    </u-chip-flat-pill>
</template>

<script>
import UChipFlatPill from "@/components/publish/styles/chips/u-chip-flat-pill.vue";

export default {
    props: {},
    components: {
        UChipFlatPill,
    },
    data() {
        return {};
    },
    mounted() {},
    methods: {},
};
</script>

<style lang="scss" scoped></style>
