<template>
    <u-pagination-text-tile v-bind="$attrs"  @input="search"></u-pagination-text-tile>
</template>

<script>
import UPaginationTextTile from "@/components/publish/styles/paginations/u-pagination-text-tile.vue";

export default {
    props: {},
    components: {
        UPaginationTextTile,
    },
    data() {
        return {};
    },
    mounted() {},
    methods: {
        search(page){
            this.$emit('input', page)
        }
    },
};
</script>

<style lang="scss" scoped></style>
